import React, { useState, useEffect, useMemo } from 'react';

const PreFilledForm = ({ onFormSubmit2, formSubmitted, isLoading, design }) => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    countryCode: '+1'
  });
  const [errors, setErrors] = useState({});
  const [isMobile, setIsMobile] = useState(false);


  const buttonStyle = useMemo(() => {
    switch (design) {
      case 'kate':
        return {
          backgroundColor: '#4ABA75',
          color: '#FFFFFF',
          hoverBg: '#3D9D62',
          padding: '0.75rem 1.5rem',
          fontSize: '1.125rem',
          height: 'auto',
          minHeight: '3rem',
          borderRadius: '9999px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem'
        };
      case 'orange':
        return {
          backgroundColor: '#eb9637',
          color: '#FFFFFF',
          hoverBg: '#d88730',
          padding: '0.5rem 1rem',
          fontSize: '1rem',
          height: 'auto',
          minHeight: '2.5rem',
          borderRadius: '0.375rem'
        };
      case 'cloobeck':
        return {
          backgroundColor: '#1430AD',
          color: '#FFFFFF',
          hoverBg: '#1430AD',
          padding: '0.5rem 1rem',
          fontSize: '1rem',
          height: 'auto',
          minHeight: '2.5rem',
          borderRadius: '0.375rem'
        };
      case 'yobidark':
        return {
          background: 'linear-gradient(225deg, #0ea5e9 0%, #2dd4bf 100%)',
          backgroundColor: '#0ea5e9', // Fallback color (sky-500)
          color: '#FFFFFF',
          hoverBg: '#0284c7', // sky-600
          padding: '0.5rem 1rem',
          fontSize: '1rem',
          height: 'auto',
          minHeight: '2.5rem',
          borderRadius: '0.375rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem'
        };
      default:
        return {
          backgroundColor: '#4B0082', // Changed to purple-900
          color: '#FFFFFF',
          hoverBg: '#3B0062', // Slightly lighter purple for hover
          padding: '0.5rem 1rem',
          fontSize: '1rem',
          height: 'auto',
          minHeight: '2.5rem',
          borderRadius: '0.375rem'
        };
    }
  }, [design]);

  const buttonText = "Submit";

  const LoadingSpinner = useMemo(() => () => (
    <svg className={`animate-spin h-5 w-5 ${design === 'yobidark' ? 'text-blue-400' : 'text-purple-500'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  ), [design]);
  
  // Initialize name and phone number from formSubmitted
  useEffect(() => {
    if (formSubmitted) {
      setFormData({
        name: formSubmitted.name || '',
        phoneNumber: (formSubmitted.countryCode + formSubmitted.phoneNumber) || '',
        countryCode:formSubmitted.countryCode || '+1',
        email: ''
      });
    }
  }, [formSubmitted]);

  const validatePhoneNumber = (phoneNumber) => {
    // Regex pattern to validate exactly 10 digits
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  };

  const validateEmail = (email) => {
    // Basic regex for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateForm = () => {
    const newErrors = {};

    // Check if email is valid
    if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    setErrors(newErrors);

    // If there are no errors, the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form before submitting
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    // Call the parent onFormSubmit function after validation
    if (onFormSubmit2) {
      onFormSubmit2(formData); // Pass the form data to the parent component
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input
          type="text"
          name="name"
          value={formData.name}
          readOnly // Name is read-only
          className={`mb-4 py-2 px-4 border rounded-lg w-full max-w-md ${design === 'yobidark' ? 'bg-gray-800 text-white border-gray-700' : 'bg-gray-100'}`}
        />
      </div>
      <div>
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          readOnly // Phone number is read-only
          className={`mb-4 py-2 px-4 border rounded-lg w-full max-w-md ${design === 'yobidark' ? 'bg-gray-800 text-white border-gray-700' : 'bg-gray-100'}`}
        />
      </div>
      <div>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Enter your email"
          className={`mb-4 py-2 px-4 border rounded-lg w-full max-w-md ${design === 'yobidark' ? 'bg-gray-800 text-white border-gray-700 placeholder-gray-400' : ''}`}
        />
        {errors.email && (
          <p className={`${design === 'yobidark' ? 'text-red-400' : 'text-red-500'}`}>{errors.email}</p>
        )}
      </div>
      <button
      type="submit"
        className={`w-full max-w-lg mx-auto
                ${isMobile ? `active:bg-${buttonStyle.hoverBg}` : `hover:bg-${buttonStyle.hoverBg}`}
                flex items-center justify-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 text-sm sm:text-base`}
        style={{
          background: buttonStyle.background || '',
          backgroundColor: buttonStyle.backgroundColor,
          color: buttonStyle.color,
          padding: buttonStyle.padding,
          fontSize: buttonStyle.fontSize,
          height: buttonStyle.height,
          minHeight: buttonStyle.minHeight,
          borderRadius: buttonStyle.borderRadius,
          display: buttonStyle.display,
          alignItems: buttonStyle.alignItems,
          justifyContent: 'center',
          gap: buttonStyle.gap
        }}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <LoadingSpinner />
            <span className="ml-2">Connecting...</span>
          </>
        ) : (
          <span>{buttonText}</span>
        )}
      </button>
    </form>
  );
};

export default PreFilledForm;