import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { RetellWebClient } from 'retell-client-js-sdk';
import RandomWaveViz from './RandomWaveViz';
import StarRating from './StarRating';
import ReactGA from "react-ga4";
import { useNavigate, useSearchParams } from 'react-router-dom';
import FeedbackForm from './feedbackFormVIPPage';
import QRCode from 'qrcode';

const DEFAULT_PHONE_NUMBER = '+14152334023';
const DEFAULT_AGENT_ID = '893c8f270b4a5fb7bebc5146910b420e';

const ShareTestCallCard = ({
                               practiceName,
                               phoneNumber,
                               isExpanded,
                               setIsExpanded,
                               hasTriggeredConfetti,
                               setHasTriggeredConfetti,
                               isCustomPractice,
                               agentID,
                               vipId,
                               vipConfig,
                               label,
                               token,
                               synthConversationId
                           }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [retellWebClient, setRetellWebClient] = useState(() => new RetellWebClient());
    const [retellStartCallData, setRetellStartCallData] = useState(null);
    const [callInProgress, setCallInProgress] = useState(false);
    const [isTalking, setIsTalking] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hasFetchedRef = useRef(false);
    const [rating, setRating] = useState(0);
    const [callEnded, setCallEnded] = useState(false);
    const navigate = useNavigate();
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [feedbackData, setFeedbackData] = useState(null)
    const [isFeedback, setIsFeedback] =  useState('false')
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [shareSuccess, setShareSuccess] = useState(false);
    const [searchParams] = useSearchParams();
    const [callerName, setCallerName] = useState('');
    const [showCallerInput, setShowCallerInput] = useState(false);
    const [linkExpired, setLinkExpired] = useState(false);
    console.log(vipConfig)

    useEffect(() => {
      const postToken = async () => {
        // Ensure form data is not null or empty before posting
        
        if (feedbackData) {
          setIsLoading(true);
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/register-lead`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  name: feedbackData.name, // Use the name from formData
                  phone: feedbackData.phoneNumber.toString() ? feedbackData.phoneNumber : "+11234567890", // Check for phoneNumber, else default text
                  email: feedbackData.email, // Include the email from formData,
                  token: token
                }),
            });
            const data = await response.json();
            console.log('Response from API:', data);
            
          } catch (error) {
            console.error('Error posting data:', error);
            
          } finally {
            setIsLoading(false);
          }
        }
      };
      postToken();
    }, [feedbackData]); 

    useEffect(() => {
        // Get both parameters while maintaining the existing URL structure
        const requireCallerName = searchParams.get('caller_name');
        const token = searchParams.get('token'); // This is already handled via props, just showing the format
        
        // Only set showCallerInput if caller_name=true
        setShowCallerInput(requireCallerName === 'true');
    }, [searchParams]);

    const findValueInJsonString = (jsonString, keyToFind) => {


        try {
            // Escape the key to avoid regex special characters issues
            const escapedKey = keyToFind.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

            // Regular expression to find the key and its value
            // Handles both single and double quotes around the value
            const regex = new RegExp(`['"]${escapedKey}['"]\\s*:\\s*['"](.*?)['"]`, 's'); // Non-greedy match

            // Search for the key in the string
            const match = jsonString.match(regex);

            // Return the value if found
            if (match && match[1]) {
                return match[1];
            } else {
                console.log(`Key "${keyToFind}" not found.`);
                return null;
            }
        } catch (error) {
            console.error('Error searching in JSON string:', error);
            return null;
        }

    };

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/customcall",
            title: "Call Custom AI Dental Assistant"
        });
    }, []);

    const fetchRetellCallData = useCallback(async () => {
        console.log("here")
        if (hasFetchedRef.current ) return retellStartCallData;
        console.log("here2")
        try {
            
            const url = `${process.env.REACT_APP_API_URL}/create-share-trainer-web-call/${vipConfig["agent_id"]}`;
            const headers = {
                'Content-Type': 'application/json'
            };
            const body = {
                agent_id: vipConfig["agent_id"],
                synth_conversation_id: synthConversationId,
                train:true,
                metadata: {}
              };
                
            if (callerName) {
                body.metadata.caller_name = callerName;
            }

            // Include label in the metadata if it exists
            if (label) {
                body.metadata.label = label;
            }
            if (synthConversationId) {
                body.metadata.synth_conversation_id = synthConversationId;
            }
          
          
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
              });

            

            if (!response.ok) {
                setLinkExpired(true);
                throw new Error(`HTTP error! Status: ${response.status}`);
               

            }

            const data = await response.json();
            setRetellStartCallData(data);
            hasFetchedRef.current = true;
            return data;
        } catch (error) {
            console.error("Error fetching Retell call data:", error);
            return null;
        }
    }, [agentID, label, retellStartCallData, callerName]);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const checkAudioPermissions = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            return true;
        } catch (err) {
            console.error("Error accessing microphone:", err);
            return false;
        }
    };

    const handleCallClick = async () => {
        if (callInProgress || isLoading) return;

        const hasPermission = await checkAudioPermissions();
        if (!hasPermission) {
            const confirmCall = window.confirm("No microphone detected. Would you like to call the phone number instead?");
            if (confirmCall) {
                window.location.href = `tel:${phoneNumber || DEFAULT_PHONE_NUMBER}`;
            }
            return;
        }

        try {
            setIsLoading(true);

            const newRetellWebClient = new RetellWebClient();
            setRetellWebClient(newRetellWebClient);

            const data = await fetchRetellCallData();
            if (!data || !data.results || !data.results.access_token) {
                throw new Error("Failed to obtain access token");
            }

            await newRetellWebClient.startCall({
                accessToken: data.results.access_token,
            });

            setCallInProgress(true);
        } catch (error) {
            console.error("Error starting call:", error);
            alert("There was an error starting the call");
        } finally {
            setIsLoading(false);
        }
    };

    const stopCall = useCallback(() => {
        try {
            retellWebClient.stopCall();
            setCallInProgress(false);
            setIsTalking(false);
            hasFetchedRef.current = false;
            setCallEnded(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        } catch (error) {
            console.error("Error stopping call:", error);
        }
    }, [retellWebClient]);

    const handleRatingSubmit = (rating, feedback) => {
        ReactGA.event({
            category: "User Interaction",
            action: "Rating Submitted",
            label: `Rating: ${rating}, Feedback: ${feedback.slice(0, 50)}...`
        });
        // navigate('/talk-to-yobi-team');
        setPopupVisible(true);
      
  };
    const closePopup = () => {
        setPopupVisible(false);
        window.location.reload();
    };
    const handleFeedbackForm = (rating, feedback, submissionData) => {
      handleRatingSubmit(rating, feedback)
      setFeedbackData(submissionData)
    
};


    useEffect(() => {
        const setupEventListeners = (client) => {
            client.on("agent_start_talking", () => setIsTalking(true));
            client.on("agent_stop_talking", () => setIsTalking(false));
            client.on("call_started", () => console.log("call started"));
            client.on("call_ended", () => {
                console.log("call ended");
                setCallInProgress(false);
                setIsTalking(false);
                setCallEnded(true);
            });
            client.on("error", (error) => {
                console.error("Retell error:", error);
                if (error.name === "NotAllowedError") {
                    alert("Microphone access was denied. Please allow microphone access and try again.");
                } else if (error.name === "NotFoundError") {
                    alert("No microphone was found. Please check your audio settings and try again.");
                }
                stopCall();
            });
        };

        setupEventListeners(retellWebClient);

        return () => {
            retellWebClient.removeAllListeners();
        };
    }, [retellWebClient, stopCall]);

    const CheckmarkIcon = useMemo(() => () => (
        <svg className="w-5 h-5 text-[#7128d7] mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
        </svg>
    ), []);

    const LoadingSpinner = useMemo(() => () => (
        <svg className="animate-spin h-5 w-5 text-purple-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    ), []);

    const isDefaultAgent = useMemo(() => agentID === DEFAULT_AGENT_ID, [agentID]);
    const hasCustomPracticeName = useMemo(() => practiceName && practiceName.toLowerCase() !== 'your practice', [practiceName]);

    // Generate QR code when call ends
    useEffect(() => {
        if (callEnded) {
            QRCode.toDataURL(window.location.href)
                .then(url => {
                    setQrCodeUrl(url);
                })
                .catch(err => {
                    console.error('Error generating QR code:', err);
                });
        }
    }, [callEnded]);

    const handleShare = async () => {
        const shareUrl = window.location.href;
        const shareText = `Talk to ${vipConfig["agent_name"] || "an AI"} created by ${vipConfig["company_name"]}`;

        // Check if Web Share API is supported (mainly mobile devices)
        if (navigator.share) {
            try {
                await navigator.share({
                    title: shareText,
                    text: shareText,
                    url: shareUrl,
                });
            } catch (err) {
                console.log('Error sharing:', err);
            }
        } else {
            // Fallback to copying to clipboard
            try {
                await navigator.clipboard.writeText(shareUrl);
                setShareSuccess(true);
                setTimeout(() => setShareSuccess(false), 2000); // Reset after 2 seconds
            } catch (err) {
                console.log('Error copying to clipboard:', err);
            }
        }
    };

    const renderContent = () => {
        if (linkExpired) {
            return (
                <div className="text-center py-8">
                    <div className="mb-4">
                        <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        Link Expired
                    </h3>
                    <p className="text-gray-600 mb-6">
                        This link is no longer active.
                    </p>
                    <button
                        onClick={() => window.location.href = 'https://yobi.app'}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#7128d7] hover:bg-[#5f22b5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#7128d7]"
                    >
                        Visit Yobi
                    </button>
                </div>
            );
        }

        if (callInProgress) {
            return (
                <div className="mt-6 flex flex-col items-center">
                    <RandomWaveViz isTalking={isTalking} />
                    <button
                        className="mt-4 py-2 px-4 bg-gray-400 text-white font-semibold rounded-lg 
                            hover:bg-gray-500 transition duration-300"
                        onClick={stopCall}
                    >
                        Finish
                    </button>
                </div>
            );
        }

        if (callEnded) {
            return (
                <div className="mt-12 flex flex-col items-center space-y-4">
                    <h2 className="text-xl font-semibold text-gray-900">
                        Share {vipConfig["agent_name"] || "this AI"} with others!
                    </h2>
                    <button
                        onClick={handleShare}
                        className="flex items-center gap-2 px-6 py-3 bg-[#7128d7] text-white font-semibold rounded-lg 
                            hover:bg-[#5f22b5] transition duration-300 shadow-md"
                    >
                        <svg 
                            className="w-5 h-5" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                            />
                        </svg>
                        {shareSuccess ? 'Link Copied!' : 'Share'}
                    </button>
                    {/* <p className="text-gray-600 text-sm text-center">
                        {navigator.share ? 
                            'Share this AI assistant with your friends' : 
                            'Copy the link to share this AI assistant'}
                    </p> */}
                </div>
            );
        }

        return (
            <>
                {showCallerInput && (
                    <div className="mb-6">
                        <label htmlFor="callerName" className="block text-sm font-medium text-gray-700 mb-2">
                            Please enter your name to continue
                        </label>
                        <div className="flex gap-4">
                            <input
                                type="text"
                                id="callerName"
                                className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#7128d7]"
                                placeholder="Your name"
                                value={callerName}
                                onChange={(e) => setCallerName(e.target.value)}
                            />
                        </div>
                    </div>
                )}

                {/* Call button - keep the disabled logic but show input field always when showCallerInput is true */}
                <button
                    className={`w-full py-3 px-4 bg-[#7128d7] text-white font-semibold rounded-lg shadow-md 
                        hover:bg-[#5f22b5] transition duration-300 ease-in-out
                        focus:outline-none focus:ring-2 focus:ring-[#7128d7] focus:ring-opacity-50 mb-8
                        ${(showCallerInput && !callerName) ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleCallClick}
                    disabled={isLoading || (showCallerInput && !callerName)}
                >
                    {isLoading ? (
                        <div className="flex items-center justify-center gap-2">
                            <LoadingSpinner />
                            <span>Connecting...</span>
                        </div>
                    ) : (
                        <span>Train Now</span>
                    )}
                </button>

                {/* Tips section */}
                <div className="relative bg-white/90 backdrop-blur-sm rounded-lg shadow-sm border border-gray-200 p-6">
                    <div className="absolute -top-3 left-4 bg-[#7128d7] text-white px-3 py-1 text-sm font-semibold rounded-full">
                        Tips
                    </div>
                    <div className="space-y-4 mt-2">
                        <div className="flex items-start gap-3">
                            <CheckmarkIcon />
                            <p className="text-gray-700">
                                {vipConfig["agent_name"] ? 
                                    `${vipConfig["agent_name"]} is an AI Teammate created by ${vipConfig["company_name"]}` :
                                    `This is an AI Teammate created by ${vipConfig["company_name"]}`}
                            </p>
                        </div>
                        <div className="flex items-start gap-3">
                            <CheckmarkIcon />
                            <div className="space-y-2">
                                <p>{`Train ${vipConfig["agent_name"]} by talking and correcting their responses in real-time.`}</p>
                                
                            </div>
                        </div>
                        <div className="flex items-start gap-3">
                            <CheckmarkIcon />
                            <p>{`Share your business knowledge with ${vipConfig["agent_name"]} and they'll remember everything you teach them.`}</p>
                        </div>
                        <div className="flex items-start gap-3">
                            <CheckmarkIcon />
                            <p className="text-gray-700">
                                You can end the Training Session at any time by clicking "Finish"
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="p-6">
            {/* Company heading */}
            <div className="text-center mb-8">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
                    {linkExpired ? (
                        <>The link you have is expired</>
                    ) : (
                        vipConfig["agent_name"] ? (
                            <>Start a Training Session with {vipConfig["agent_name"]} </>
                        ) : (
                            <>Talk to an AI created by <span className="text-[#7128d7]">{vipConfig["company_name"]}</span></>
                        )
                    )}
                </h1>
            </div>

            {renderContent()}
        </div>
    );
};
    
export default React.memo(ShareTestCallCard);
    